@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');

:root {
  --bg-bw-02: #2F2F2F;
  --gradient-shadow: linear-gradient(
		45deg,
		#01F68D,
		#1C84DC
	);
}



body {
  background-color: #161616;
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

.table {
  overflow: auto;
}

.table td  {
  border: 1px solid white!important;
  padding: 4px
}

.table th  {
  border: 1px solid white!important;
  padding: 4px
}

::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--bg-bw-02);        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(164, 164, 164, 1)    /* color of the scroll thumb */
  /* border-radius: 20px;     */
}



@layer base {
    @font-face {
      font-family: 'kensmark-03';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("./assets/fonts/Kensmark-Bold-Slant//Kensmark-03-Bold.ttf") format('woff2');
    }
    @font-face {
      font-family: 'cairo';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("./assets/fonts/Cairo/Cairo-VariableFont_slnt\,wght.ttf") format('woff2');
    }
    @font-face {
      font-family: 'cairo-bold';
      font-style: normal;
      /* font-weight: 700; */
      font-display: swap;
      src: url("./assets/fonts/Cairo/Cairo-Bold.ttf") format('woff2');
    }
    @font-face {
      font-family: 'poppins';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("./assets/fonts/Poppins/Poppins-Light.ttf") format('woff2');
    }
    @font-face {
      font-family: 'montserrat';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format('woff2');
    }
    @font-face {
      font-family: 'inter';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("./assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf") format('woff2');
    }
  }
  

@layer components {
  .group:hover .group-hover\:block {
    display: block;
  }
  
  .group-hover\:block {
    display: none;
  }
  
  .group:hover .group-hover\:flex {
    display: flex;
  }
  
  .group-hover\:flex {
    display: none;
  }

  
    .text-gradient {
      background: var(--G-Linear, linear-gradient(152deg, #01F68D 22.14%, #1C84DC 79.48%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    
    .cart-gradient {
        fill: var(--G-Linear, linear-gradient(180deg, #01F68D 22.14%, #1C84DC 79.48%));
    }
    .save-gradient {
      background: var(--G-Linear, linear-gradient(152deg, #01F68D 22.14%, #1C84DC 79.48%));
    }
    .input-parent {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
    .search-input {
      border: 3px solid var(--G-Linear, #01F68D);
    }
    .overlay {
      background: #515151;
      background-blend-mode: multiply;
    }

    .box-shadow-1 {
      box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.15);
    }

    input[type="checkbox"] {
      box-sizing: border-box;
      width: 22px;
      height: 22px;
      padding: 0;
      appearance: none;
      background-color: var(--bg-bw-02);
      color: white;
      outline: none;
      transition: outline 0.1s;
      padding: 4px;
    }
    input[type="checkbox"]:checked {
      background-size: cover;
      padding: 2px;
      background-color: transparent;

    }
    
    input[type="checkbox"]:not(:disabled):checked {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12" viewBox="0 0 32 32" xml:space="preserve"><path style="fill: %23FFFFFF" d="M11.941,28.877l-11.941-11.942l5.695-5.696l6.246,6.246l14.364-14.364L32,8.818"/></svg>');
    }
    
 

    .border-gradient {
      position: relative;
      background: #fff;
      border-radius: 8px;
      z-index: 1;
    }
    .border-gradient:after {
      filter: blur(4px);
    }
    .border-gradient:before,
    .border-gradient:after {
      border-radius: 8px;
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      background: var(--gradient-shadow);
      background-size: 400%;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: -1;
      animation: animate 7s linear infinite;
    }
    
    .cart-translate {
      transform: translateX(0px)!important;
    }

    .menu-translate {
      transform: translateX(0)!important;
    }

    @keyframes animate {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 300% 0;
      }
      100% {
        background-position: 0 0;
      }
    }

    
.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: #01F68D;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
  
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3.2px;
  left: 36.8px;
  width: 6.4px;
  height: 17.6px;
  border-radius: 20%;
  background: #01F68D;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Add this to your global CSS file or within a <style> tag */
.group:hover .group-hover\:flex,
.group:hover .group-hover\:block {
  display: block;
}

.group-hover\:flex,
.group-hover\:block {
  display: none;
}


    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }


    .MuiSlider-valueLabel {
      display: none;
    }

    

  }